/* Footer Styles */
.footer {
  background-color: #2b3035;
  color: white;
  position: relative;
  z-index: 1;
}

.footer .left-side,
.footer .center,
.footer .right-side {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer .left-side,
.footer .center {
  transform: translate(0, 9px);
}


