.navbar {
    z-index: 1;
}

/* Navbar Brand */
.navbar .navbar-brand {
    font-family: "Roboto";
    font-size: 25px;
    color: white;
    transition: color 0.5s ease;
}

/* Navbar Links */
.navbar .nav-link {
    font-family: "Roboto";
    font-size: 20px;
    color: white;
    transition: color 0.3s ease;
    margin-left: 5px;
}

/* Section */
.section {
    position: relative;
    z-index: 1;
}

/* Theme Label */
.theme-label {
    font-family: "Roboto";
    color: white;
    margin-right: 10px;
}

/* Responsive Switch Container */
.switch {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 576px) {
    .switch {
        flex-direction: column;
        align-items: flex-start;
    }

    .theme-label {
        margin-right: 0;
        margin-bottom: 5px;
    }
}
