/* General Styles */
.App {
    position: relative;
    overflow-x: hidden;
}

.page-section {
    position: relative;
    z-index: 1; /* Ensure sections appear above the particle background */
    padding-top: 70px; /* Adjust based on the height of your navbar */
}

/* Dark Mode Styles */
.App.dark {
    background-color: #121212;
    color: white;
}

.App.dark .form-container {
    padding: 40px;
    background-color: #3b3b3b; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.8); 
}

.App.dark .rectangle {
    background-color: #2176D9; /* Fallback color */
    background-image: linear-gradient(90deg, #C7816C, #2176D9);
}

.App.dark .navbar .navbar-brand:hover,
.App.dark .navbar .nav-link:hover {
    color: #009DFF;
}

.App.dark .Steven-Le {
    color: #009DFF;
}

.App.dark .AboutMeContainer .left-side {
    border-width: 3px;
    border-style: solid;
    border-image-slice: 1;
    padding: 5px;
    border-image-source: linear-gradient(90deg, #C7816C, #2176D9);
}

.App.dark .leftside-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-width: 3px;
    border-style: solid;
    border-image-slice: 1;
    padding: 5px;
    border-image-source: linear-gradient(90deg, #C7816C, #2176D9);
    margin-right: 22%;
    margin-left: 2%;
}

/* Light Mode Styles */
.App.light {
    background-color: white;
    color: black;
}

.App.light .form-container {
    padding: 40px;
    background-color: #f9f9f9; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15); 
}

.App.light .rectangle {
    background-color: #F7C9B7; /* Fallback color */
    background-image: linear-gradient(90deg, #F7C9B7, #D2CAFB);
}

.App.light .navbar .navbar-brand:hover,
.App.light .navbar .nav-link:hover {
    color: #F16529;
}

.App.light .Steven-Le {
    color: #F16529;
}

.App.light .AboutMeContainer .left-side {
    border-width: px;
    border-style: solid;
    border-image-slice: 1;
    padding: 5px;
    border-image-source: linear-gradient(90deg, #F7C9B7, #D2CAFB);
}

.App.light .leftside-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border-width: 3px;
    border-style: solid;
    border-image-slice: 1;
    padding: 5px;
    border-image-source: linear-gradient(90deg, #F7C9B7, #D2CAFB);
    margin-right: 22%;
    margin-left: 2%;
}

.App.dark .rightside-project,
.App.light .rightside-project {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin-left: 22%;
    margin-right: 2%;
}