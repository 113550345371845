/* AboutMeContainer Styles */
.AboutMeContainer {
    height: auto;
    position: relative;
    z-index: 1;
}

.AboutMeContainer .rectangle {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-28%, -20%);
    width: 140px;
    height: 33px;
    z-index: -1;
}

/* About Me Text */
.about-me-text {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 25px;
    padding: 10px 5px 0 15px; /* Adjusted padding for more compact notation */
}

/* Languages Image */
.languages-image {
    max-width: 100%; /* Ensures the image doesn't exceed its container */
    height: auto; /* Maintains aspect ratio */
    align-items: center;
    justify-content: center;
}
