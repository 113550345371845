.WorksContainer {
    height: auto;
    z-index: 1;
    position: relative;
}

.WorksContainer .rectangle {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-20%, -40%);
    width: 125px;
    height: 33px;
    z-index: -1;
}

.leftside-image-col {
    padding: 20px;
}

.leftside-image {
    width: 100%; 
    height: auto;
    max-width: 100%; /* Ensure it does not exceed its container's width */

    /* Media query for medium screens (768px to 1023px) */
    @media (min-width: 768px) and (max-width: 1023px) {
        width: 90%; /* Adjust width for medium screens */
        height: auto; /* Allow height to adjust proportionally */
    }

    /* Media query for smaller screens (up to 767px) */
    @media (max-width: 767px) {
        width: 80%; /* Adjust width for smaller screens */
        height: auto; /* Allow height to adjust proportionally */
    }
}


.left-side-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 100%; /* Ensures the image doesn't exceed its container */
    height: auto; /* Maintains aspect ratio */
}

.rightside-image-col {
    padding: 20px;
}

.rightside-image {
    width: 100%; 
    height: auto;
    max-width: 100%; /* Ensure it does not exceed its container's width */
    align-items: center;
    justify-content: center;

    /* Media query for medium screens (768px to 1023px) */
    @media (min-width: 768px) and (max-width: 1023px) {
        width: 90%; /* Adjust width for medium screens */
        height: auto; /* Allow height to adjust proportionally */
    }

    /* Media query for smaller screens (up to 767px) */
    @media (max-width: 767px) {
        width: 80%; /* Adjust width for smaller screens */
        height: auto; /* Allow height to adjust proportionally */
    }
}


.right-side-text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 100%; /* Ensures the image doesn't exceed its container */
    height: auto; /* Maintains aspect ratio */
}