.ContactContainer {
    height: auto;
    z-index: 1;
    position: relative;
}

.ContactContainer .rectangle {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-32%, -20%);
    width: 190px;
    height: 33px;
    z-index: -1;
}